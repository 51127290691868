import React, { useContext } from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/template/Layout"
import { CurrentView } from '../../entities/templates';
import { PagePropsType, lang } from '../../entities/countryIndexQueries'
import useSetGlobalState from '../../components/hooks/useSetGlobalState'
import Wrapper from '../../components/template/ContainerMax';
import { SiteContext } from '../../Context/site'
import PortableText from '../../components/sanityBlockContents/PortableText';
import Text from "../../components/atoms/Text";
import PageSection from '../../components/organism/HomePageComponent';
import translations from '../../entities/translations';
import Carousel from '../../components/organism/carousel'
import {Card} from '../../components/molecule/Card'
import { imageURLBuilder, getCardSize } from '../../utils';
import Image from "../../components/atoms/Image";
import Button from "../../components/atoms/Button";

const IndexPage:React.FC<PagePropsType> = (props) => {
  const { data, pageContext } = props
  const page = data && data.pageData
  const locale = pageContext && pageContext.locale
  useSetGlobalState({ page, pageContext, locale, currentView: CurrentView.overview  })
  const { state } = useContext(SiteContext);
  const pageID = translations.globals.subMenuTranslation;
  const buttonTransaltion = translations.globals.buttons;

  return (
    <Layout seoTitle={page?.metadata?.page_title[state?.lang as lang] as string} meta={[
      { name: 'description', content: page?.metadata?.page_description ? page?.metadata?.page_description[state?.lang as lang] as string : '' },
      { name: 'keywords', content: page?.metadata?.page_keywords ? page?.metadata?.page_keywords[state?.lang as lang] as string : ''},
    ]}>
      <Wrapper className="py-12">
        { page?.countryOverViewIntroCopy && page?.countryOverViewIntroCopy[state?.lang as lang]
        && <PortableText content={page?.countryOverViewIntroCopy[state?.lang as lang]} 
          className=""
          template={{
            h2: (props: any) => <Text type="H2">{props.children}</Text>,
            normal: (props: any) => <Text type="P" ptype="BASE"  >{props.children}</Text>,
            blockquote: (props: any) => <Text type="P" ptype="BASE">{props.children}</Text>,
          }} />}

          {page?.countryOverviewGallery?.pictures?.length && 
            <Carousel arrayLength={0} showPrevNextButtons={true} setAdaptiveHeight={true}>
              {page.countryOverviewGallery?.pictures?.map((item, i: number) => (
                <Image alt={item.alt[state?.lang as lang]} src={imageURLBuilder(item.image.asset).url() as string}  key={i} />
              ))}
          </Carousel>}

          {page?.countryOverViewOutroCopy && page?.countryOverViewOutroCopy[state?.lang as lang]
          && <PortableText content={page?.countryOverViewOutroCopy[state?.lang as lang]} 
            className="" 
            template={{
              h2: (props: any) => <Text type="H2">{props.children}</Text>,
              normal: (props: any) => <Text type="P" ptype="BASE"  >{props.children}</Text>,
              blockquote: (props: any) => <Text type="P" ptype="BASE">{props.children}</Text>,
            }} />}

            <Button color='secondary' size="large" >
              {buttonTransaltion['Erfahren Sie hier mehr über uns'][state?.lang as lang]}
            </Button>
      </Wrapper>

      <PageSection readMoreLink={'#'}  sanityContext={page?.highlightsIntroduction && page?.highlightsIntroduction[state?.lang as lang]}
        id={pageID.Höhepunkte[state?.lang as lang]!} layerBackground="bg-white-smoke"
        title={state?.countryName!} >
        <Carousel arrayLength={page.countryHighlights?.length || 0}>
         {page.countryHighlights?.map((item, i: number, arr) => <Card
          key={i}
          _className="mt-2 mx-2.5 w-10/12 mb-4 opacity-20 md:opacity-100"
          imageAlt={item.hightlightImage.alt[state?.lang as lang]}
          imageUrl={imageURLBuilder(item.hightlightImage?.image).url()}
          title={item.title[state?.lang as lang] || ''}
          ctaText={buttonTransaltion.WEITERLESEN[state?.lang as lang] || ''}
          cardType="COUNTRY"
          cardSize={getCardSize(arr)}
          /> )}
        </Carousel>
      </PageSection>
    </Layout>
)}

export default IndexPage

export const query = graphql`
  query countryOverviewPage($id: String!) {
    pageData: sanityCountry(_id: {eq: $id}) {
      id: _id
      title: _rawTitle
      subTitle: _rawSubTitle(resolveReferences: {maxDepth: 10})
      heroImage: _rawCountryOverviewImage(resolveReferences: {maxDepth: 2})
      countryOverViewIntroCopy: _rawCountryOverviewIntroCopy(resolveReferences: {maxDepth: 4})
      countryOverViewOutroCopy: _rawCountryOverviewOutroCopy(resolveReferences: {maxDepth: 4})
      highlightsIntroduction: _rawHighlightsIntroduction(resolveReferences: {maxDepth: 1})
      countryHighlights: _rawCountryHighlights(resolveReferences: {maxDepth: 4})
      countryOverviewGallery : _rawCountryOverviewGallery(resolveReferences: {maxDepth: 4})
      metadata: _rawCountryOverviewMetadata
    }
  }
`
